import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as translationConfigurations from './locale';

i18n
  .use(initReactI18next)
  .init({
    /**
     * Base i18next configuration:
     *  https://www.i18next.com/overview/configuration-options
     */
    fallbackLng: 'en',
    supportedLngs: ['pt', 'en', 'es'],
    lng: 'pt',
    load: 'currentOnly',
    lowerCaseLng: true,
    preload: ['pt', 'en', 'es'],
    resources: {
      ...translationConfigurations,
    },
  });

export default i18n;
