import { CartItems } from "../pages/cart";
import { Catalogs, Price, SearchProduct } from "../types/search";

export const formatPrice = (
  price: number,
  { currency, locale }: { currency?: string; locale?: string } = {}
) =>
  new Intl.NumberFormat(locale || "pt-PT", {
    style: "currency",
    currency: currency || "EUR",
  }).format(price);

export const calculatePriceWithDiscount = (
  price: number,
  isOnSale: boolean,
  brand: Catalogs,
  userDiscounts: Partial<Record<Catalogs, number>>
): number => {
  const discounts = getDiscountsForCatalog(brand, userDiscounts);

  return getPriceWithDiscountPercentage(
    price,
    isOnSale ? discounts.sale : discounts.general
  );
};

export const isSmaller = (price1: Price, price2?: Price) => {
  if (!price2 || !price2.amount) {
    return false;
  }

  return price1.amount < price2.amount;
};

export const getPriceWithDiscountPercentage = (
  price: number,
  discountPercentage: number
) => {
  // 100 - 100 * (10 / 100) -- 10% discount
  return price - price * (discountPercentage / 100);
};

export const getDiscountsForCatalog = (
  catalog: string,
  userDiscounts: Record<string, number>
) => {
  return {
    sale: userDiscounts[`${catalog}-sale`] || 0,
    general: userDiscounts[catalog] || 0,
  };
};

export const calculateTotalWithQuantity = (
  items: SearchProduct[],
  userDiscounts: Record<string, number>,
  getItemQuantity: (product: SearchProduct) => number
): number => {
  const originalPrice = items.reduce((total, item) => {
    const discountsForCatalog = getDiscountsForCatalog(
      item.catalog,
      userDiscounts
    );

    return (
      total +
      getPriceWithDiscountPercentage(
        item.price.amount,
        item.sale ? discountsForCatalog.sale : discountsForCatalog.general
      ) *
        getItemQuantity(item)
    );
  }, 0);

  return originalPrice;
};

export const calculateDiscountedAmount = (
  initialPrice: number,
  discount: number = 0
) => initialPrice * (discount / 100);

export const calculateTotalPerItem = (quantity: number, price: number) =>
  quantity * price;

export const calculateFinalTotal = (
  items: CartItems,
  userDiscounts: Record<string, number>,
  getItemQuantity: (product: SearchProduct) => number
): number => {
  return items.reduce((total, item) => {
    return (
      total +
      calculateTotalWithQuantity(item.items, userDiscounts, getItemQuantity)
    );
  }, 0);
};
