import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { CartItems } from "../pages/cart";
import { Catalogs, SearchProduct } from "../types/search";
import {
  calculateDiscountedAmount,
  calculateTotalPerItem,
} from "../utils/price";
import { useLocalStorage } from "./useLocalStorage";
import { useQuantities } from "./useQuantities";

const CART_INITIAL_STATE = { itemsBySupplier: {} };

type Cart = { itemsBySupplier: Partial<Record<Catalogs, SearchProduct[]>> };

const noOp = () => {}
const ShoppingCartContext = createContext<ReturnType<typeof useShoppingCartWithPersistance>>({
  actions: {
    addToCart: noOp,
    clearCart:  noOp,
    getCartItems: () => [],
    getItemQuantity: () => 0,
    getTotalsPerSupplier: () => [],
    isProductInCart: () => false,
    removeFromCart: noOp,
    setItemQuantity: noOp,
  },
  cart: {
    allItems: [],
    bySupplier: {}
  },
});

export const ShoppingCartProvider: React.FC<{
  initialState?: Cart;
  localStorageKey?: string;
  children: JSX.Element
}> = ({ children, initialState, localStorageKey }) => {
  const shoppingCart = useShoppingCartFromLocalStorage(
    initialState,
    localStorageKey,
  );
  return (
    <ShoppingCartContext.Provider value={shoppingCart}>
      {children}
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};

export const useShoppingCartFromLocalStorage = (
  initialState?: Cart,
  localStorageKey: string = "dentral-shopping-cart-v0.0.1",
) => {
  const [currentState, setter] = useLocalStorage(localStorageKey, initialState);

  return useShoppingCartWithPersistance(currentState, setter);
};

export const useShoppingCartWithPersistance = (state?: Cart, persist?: (cart: Cart) => void) => {
  const [cart, _setCart] = useState<Cart>(state || CART_INITIAL_STATE);
  const [, getItemQuantity, setItemQuantity] = useQuantities();

  const setCart = useCallback((cart: Cart)=> {
    persist ? persist(cart) : {};
    _setCart(cart);
  }, [persist])

  const addToCart = useCallback(
    (productToAdd: SearchProduct) => {
      const supplierCart = productToAdd.catalog;
      const currentSupplierInCart = cart.itemsBySupplier[supplierCart] || [];

      const alreadyInCart =
        currentSupplierInCart &&
        currentSupplierInCart.find(
          (product) => product.objectID === productToAdd.objectID
        );
      if (alreadyInCart) {
        console.error("Product already in cart");
        return;
      }

      setItemQuantity(productToAdd.manufacturerRef, 1);
      setCart({
        ...cart,
        itemsBySupplier: {
          ...cart.itemsBySupplier,
          [supplierCart]: currentSupplierInCart.concat(productToAdd),
        },
      });

      return;
    },
    [cart, setCart, setItemQuantity]
  );

  const removeFromCart = useCallback(
    (productToRemove: SearchProduct) => {
      const supplierCart = productToRemove.catalog;
      const currentSupplierInCart = cart.itemsBySupplier[supplierCart] || [];

      const futureCart = currentSupplierInCart.filter(
        (product) => product.objectID !== productToRemove.objectID
      );
      setItemQuantity(productToRemove.manufacturerRef, 0);
      setCart({
        ...cart,
        itemsBySupplier: {
          ...cart.itemsBySupplier,
          [supplierCart]: futureCart,
        },
      });
    },
    [cart, setCart, setItemQuantity]
  );

  const allItems = useMemo(() => {
    return Object.keys(cart.itemsBySupplier).reduce<SearchProduct[]>(
      (allItems, supplierName) => {
        if (!cart.itemsBySupplier[supplierName as Catalogs]) {
          return allItems;
        }

        return allItems.concat(
          cart.itemsBySupplier[supplierName as Catalogs] || []
        );
      },
      []
    );
  }, [cart.itemsBySupplier]);

  const getCartItems = useCallback(() => {
    return Object.keys(cart.itemsBySupplier).reduce<CartItems>(
      (allItems, supplierName) => {
        if (!cart.itemsBySupplier[supplierName as Catalogs]) {
          return allItems;
        }

        if (cart.itemsBySupplier[supplierName as Catalogs]?.length === 0) {
          return allItems;
        }

        return allItems.concat({
          name: supplierName as Catalogs,
          items: cart.itemsBySupplier[supplierName as Catalogs] || [],
        });
      },
      []
    );
  }, [cart]);

  const isProductInCart = useCallback(
    (product: SearchProduct) => {
      return !!allItems.find(
        (productInCart) => productInCart.objectID === product.objectID
      );
    },
    [allItems]
  );

  const getTotalsPerSupplier = useCallback(
    (discounts: Partial<Record<Catalogs, number>>) => {
      return Object.keys(cart.itemsBySupplier).reduce<
        {
          name: Catalogs;
          totalWithoutDiscount: number;
          totalWithDiscount: number;
          discounts: number;
        }[]
      >((totals, supplierName) => {
        const productTotals =
          cart.itemsBySupplier[supplierName as Catalogs]?.reduce(
            (productTotals, product) =>
              productTotals +
              calculateTotalPerItem(
                getItemQuantity(product.manufacturerRef),
                product.price.amount
              ),
            0
          ) || 0;

        if (productTotals === 0) {
          return totals;
        }

        const discountedAmount = calculateDiscountedAmount(
          productTotals,
          discounts[supplierName as Catalogs]
        );

        return totals.concat({
          name: supplierName as Catalogs,
          totalWithoutDiscount: productTotals,
          totalWithDiscount: productTotals - discountedAmount,
          discounts: discountedAmount,
        });
      }, []);
    },
    [cart.itemsBySupplier, getItemQuantity]
  );

  return {
    actions: {
      addToCart,
      removeFromCart,
      setItemQuantity: (product: SearchProduct, quantity: number) =>
        setItemQuantity(product.manufacturerRef, quantity),
      getItemQuantity: (product: SearchProduct) =>
        getItemQuantity(product.manufacturerRef),
      clearCart: () => setCart(CART_INITIAL_STATE),
      getCartItems: getCartItems,
      isProductInCart,
      getTotalsPerSupplier,
    },
    cart: {
      bySupplier: cart.itemsBySupplier,
      allItems,
    },
  };
};

export type ShoppingCartActions = ReturnType<typeof useShoppingCartWithPersistance>["actions"];
export type ShoppingCartData = ReturnType<typeof useShoppingCartWithPersistance>["cart"];
