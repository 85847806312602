import getConfig from "next/config";
import "../styles/globals.css";
import "../styles/toast.css";
import "../i18n.config";
import { Auth0Provider } from "@auth0/auth0-react";
import type { AppProps } from "next/app";
import Layout from "../components/Layout";
import { ShoppingCartProvider } from "../hooks/useShoppingCart";

const { publicRuntimeConfig } = getConfig();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ShoppingCartProvider>
      <Auth0Provider
        domain={publicRuntimeConfig.AUTH0_DOMAIN}
        clientId={publicRuntimeConfig.AUTH0_CLIENT_ID}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        redirectUri={
          typeof window !== "undefined" ? window.location.origin : ""
        }
      >
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Auth0Provider>
    </ShoppingCartProvider>
  );
}

export default MyApp;
