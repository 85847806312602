import { SUPPLIERS } from "../constants";

export const unflatten = (data: any): any => {
  var result: any = {};
  for (var i in data) {
    var keys = i.split(".");
    keys.reduce(function (r, e, j) {
      return (
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 == j
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

export const transformDiscountToCSV = (discountObj: any): string => {
  const listDiscount = [];
  for (const supplier of SUPPLIERS) {
    listDiscount.push(discountObj[supplier]);
  }
  return listDiscount.join(",");
};

export const isServer = () => {
  return typeof window === "undefined";
};
