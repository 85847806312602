import styles from "../styles/Footer.module.css";
import i18n from "../i18n.config";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const { t } = useTranslation("global");
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <footer className={[styles.footer, 'print:hidden'].join(' ')}>
        <a
          href="https://www.dentral.pt/"
          target="_blank"
          rel="noopener noreferrer"
        >
          © {t("powered_by")} DENTRAL {new Date().getFullYear()}
        </a>
      <div className="relative text-left w-56 mx-auto my-4 block">
        <div>
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={() => {
              setShowLanguageDropdown(!showLanguageDropdown);
              console.log(showLanguageDropdown);
            }}
          >
            {t("languages")}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-${
            showLanguageDropdown ? "out" : "in"
          } duration-${showLanguageDropdown ? "100" : "75"} transform opacity-${
            showLanguageDropdown ? "100" : "0"
          } scale-${showLanguageDropdown ? "100" : "95"}`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <a
              onClick={() => {
                changeLanguage("pt");
                setShowLanguageDropdown(false);
              }}
              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-50"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
            >
              {t("available_languages.pt")}
            </a>
            <a
              onClick={() => {
                changeLanguage("en");
                setShowLanguageDropdown(false);
              }}
              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-50"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-1"
            >
              {t("available_languages.en")}
            </a>
            <a
              onClick={() => {
                changeLanguage("es");
                setShowLanguageDropdown(false);
              }}
              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-50"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-2"
            >
              {t("available_languages.es")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
