// This order is very important as it matches the order of the Auth0 rule
export const SUPPLIERS = [
  "dentaleader",
  "henryschein",
  "dentalexpress",
  "montellano",
  "douromed",
  "dentaleader-sale",
  "henryschein-sale",
  "dentalexpress-sale",
  "montellano-sale",
  "douromed-sale",
];
