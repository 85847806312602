import { Fragment, MouseEventHandler } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Popover, Transition } from "@headlessui/react";
import {
  ShoppingCartIcon,
  LoginIcon,
  DocumentTextIcon,
  MenuIcon,
  SearchIcon,
  SupportIcon,
  XIcon,
  QuestionMarkCircleIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "../hooks/useShoppingCart";
import { isServer } from "../utils";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type SolutionItem = {
  name: string;
  description: string;
  href?: string;
  onClick?: () => void;
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  render?: (context: boolean) => boolean;
};

const Header = () => {
  const { user, isAuthenticated, buildAuthorizeUrl, loginWithPopup, logout } =
    useAuth0();
  const router = useRouter();
  const { t } = useTranslation("global");
  const { cart } = useShoppingCart();

  // const onClickLogin = () => {
  //   return buildAuthorizeUrl({
  //     redirect_uri: window.location.origin,
  //     redirectMethod: "replace",
  //   })
  //     .then((url) => {
  //       router.push(url);
  //     })
  //     .catch(console.log);
  // };

  const onClickLogout = () => {
    return logout({
      returnTo: window.location.origin,
    });
  };

  const signoutHeaderItem = {
    name: t("sign_out"),
    description: t("sign_out_description"),
    onClick: (e: any) => {
      e.preventDefault();
      return onClickLogout();
    },
    icon: LogoutIcon,
    render: (isAuthenticated: boolean) => isAuthenticated,
  };

  const cartHeaderItem = {
    name: `${t("cart")} (${isServer() ? 0 : cart.allItems.length})`,
    description: t("cart_description"),
    href: "/cart",
    icon: ShoppingCartIcon,
  };

  const LOGO_PATH =
    "https://static.wixstatic.com/media/c206c5_cacde61c36804e598b599f82366eb195~mv2.jpg/v1/crop/x_0,y_63,w_1012,h_316/fill/w_512,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/LOGO%20DENTRAL_%20JPEG.jpg";

  const getSolutions = (
    onClickLogin: () => void,
    onClickLogout: () => void
  ): SolutionItem[] => [
    {
      name: t("sign_in"),
      description: t("sign_in_description"),
      onClick: onClickLogin,
      icon: LoginIcon,
      render: (isAuthenticated: boolean) => !isAuthenticated,
    },
    {
      name: t("sign_up"),
      description: t("sign_up_description"),
      href: "/signup",
      icon: DocumentTextIcon,
      render: (isAuthenticated: boolean) => !isAuthenticated,
    },
    {
      name: t("search"),
      description: t("search_description"),
      href: "/search",
      icon: SearchIcon,
      render: (isAuthenticated: boolean) => isAuthenticated,
    },
    {
      name: `${t("cart")} (${cart.allItems.length})`,
      description: t("cart_description"),
      href: "/cart",
      icon: ShoppingCartIcon,
      render: (isAuthenticated: boolean) => isAuthenticated,
    },
    {
      name: t("sign_out"),
      description: t("sign_out_description"),
      onClick: onClickLogout,
      icon: LogoutIcon,
      render: (isAuthenticated: boolean) => isAuthenticated,
    },
  ];

  const solutions = getSolutions(
    () => {
      buildAuthorizeUrl({
        redirect_uri: window.location.origin,
        redirectMethod: "replace",
      })
        .then((url) => {
          router.push(url);
        })
        .catch(console.log);
    },
    () => {
      logout({
        returnTo: window.location.origin,
      });
    }
  );

  const shouldRender = (item: SolutionItem) => {
    if (item.render) {
      return item.render(isAuthenticated);
    }

    return true;
  };

  return (
    <Popover className="relative bg-white">
      <div className="w-full px-4 sm:px-6 print:px-0 print:py-0">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 print:py-4">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link href="/" passHref>
              <a>
                <span className="sr-only">{t("head_title")}</span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src={LOGO_PATH}
                  alt="Dentral logo"
                />
              </a>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden print:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dentral-main">
              <span className="sr-only">{t("open_menu")}</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          {!isAuthenticated ? (
            <>
              <div className="hidden md:flex items-center justify-end">
                <button
                  onClick={() => loginWithPopup()}
                  className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {t("sign_in")}
                </button>
              </div>
              <div className="hidden md:flex items-center justify-end">
                <button
                  onClick={() => router.push("/signup")}
                  className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {t("sign_up")}
                </button>
              </div>
            </>
          ) : (
            <Fragment>
              <div className="hidden md:flex items-center justify-end">
                <a
                  suppressHydrationWarning
                  href={cartHeaderItem.href}
                  className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {cartHeaderItem.name}
                </a>
              </div>
              <div className="hidden md:flex items-center justify-end">
                <a
                  onClick={signoutHeaderItem.onClick}
                  className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {signoutHeaderItem.name}
                </a>
              </div>
              <div className="flex space-x-2 print:hidden">
                <div className="relative w-12 h-12">
                  <Link href="/account" passHref>
                    <a>
                      <img
                        className="rounded-full border border-gray-100 shadow-sm"
                        src={user?.picture}
                        alt="user image"
                      />
                      <div className="absolute top-0 right-0 h-3 w-3 my-1 border-2 border-white rounded-full bg-green-400 z-2"></div>
                    </a>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={LOGO_PATH}
                    alt="Dentral logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dentral-main">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) =>
                    shouldRender(item) ? (
                      <a
                        key={item.name}
                        onClick={item.onClick}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon
                          className="flex-shrink-0 h-6 w-6 text-dentral-main"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </a>
                    ) : null
                  )}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
